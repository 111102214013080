//*// SORTABLE //*//

.pat-sortable {
	> *:hover {
		cursor: move;
		cursor: -webkit-grab;
		background: #FFF5CD;
	}
	.item-dragging, .item-dragging:hover {
		cursor: -webkit-grabbing !important;
		background: #f2f2f2;
	}
	
}
.item-dragging.dragging {
		background: #fea;
		cursor: -webkit-grabbing !important;
		padding: 6px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.17);
	}
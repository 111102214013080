//*// DROP ZONE //*//

.pat-dropzone {
  .dz-notice {
    margin-bottom: @plone-padding-base-vertical; //like a label
  }
  .dz-default {
    border-radius: @plone-border-radius-large;
    background-color: @plone-portlet-header-bg;
    border: 3px dotted @plone-gray-lighter;
    margin-bottom: 20px;
    padding: @plone-padding-base-horizontal;
    color: @plone-gray;
  }
}

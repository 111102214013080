//*// TABLE SORTER //*//

.pat-tablesorter {
	th:hover {color:@plone-link-color;}
	.sortdirection {
		font-size: 75%;
		opacity: .5;
		position: relative;
		top: -1px;
		padding: 2px;
		min-width: 15px;
		display: inline-block;
	}
}
//*// HEADER //*//
#portal-header {
    margin: 15px auto 20px;
    .clearfix(); //clear floats
}
#portal-logo {
    float: left;
}
#portal-languageselector {
    clear: both;
    display: inline-block;
    float: left;
    margin-right: 29px;
    padding-top: 5px;
    li {
        display: inline-block;
        margin-right: 10px;
    }
}
#portal-anontools {
	float: right;
	ul {
		padding-right: 0;
	}
}

//mobile search
#portal-searchbox {
    clear: left;
    margin-left: 3px;
    margin-top: 60px;
    padding-top: 20px;
    position: absolute;
    white-space: nowrap;
    width: 67%;
    z-index: 2;
    .searchSection {display: none;} //non on mobile
    [type="submit"] {display: none;} //submit hidden on mobile
    label {font-size: 86.667%}
}
#searchbox_currentfolder_only {
    vertical-align: middle;
}

//non mobile search
@media (min-width: @plone-grid-float-breakpoint) {
    #portal-header {
        margin-bottom: 15px;
    }
	#portal-languageselector {
        clear: none;
        float: right;
	}
    #portal-searchbox {
        clear: none;
        float: right;
        margin-left: 1em;
        margin-top: 5px; //visual balance
        max-width: 50%;
        padding-top: 0;
        position: relative;
        width: 250px;
        .searchSection {display: inherit;} //yes for non mobile
        [type="text"] {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            float: left;
            width: 180px; //searchboxwidth (250px) - submit (70px)
        }
        [type="submit"] {
            display: inline-block;
            margin-left: -1px;
            vertical-align: top;
            width: 70px;
        }
    }
}
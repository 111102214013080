//*// FONTS //*//

// 300 Light
.font-face(
    @font-path:     'roboto/';
	@file-name:     'roboto-light';
	@font-family:   'Roboto';
	@font-weight:   300;
	@font-style:    normal;
	@svg-id:        'robotolight';
    @local:         ~"local('Roboto Light'),";
);

// 300 Light Italic
.font-face(
    @font-path:     'roboto/';
	@file-name:		'roboto-lightitalic';
	@font-family:	'Roboto';
	@font-weight:	300;
	@font-style:	italic;
	@svg-id:		'robotolight_italic';
    @local:         ~"local('Roboto Light Italic'),";
);

// 400 Regular
.font-face(
    @font-path:     'roboto/';
	@file-name:		'roboto-regular';
	@font-family:	'Roboto';
	@font-weight:	400;
	@font-style:	normal;
	@svg-id:		'robotoregular';
    @local:         ~"local('Roboto Regular'),";
);

// 400 Italic
.font-face(
    @font-path:     'roboto/';
	@file-name:		'roboto-italic';
	@font-family:	'Roboto';
	@font-weight:	400;
	@font-style:	italic;
	@svg-id:		'robotoitalic';
    @local:         ~"local('Roboto Italic'),";
);

// 500 Medium
.font-face(
    @font-path:     'roboto/';
    @file-name:		'roboto-medium';
	@font-family:	'Roboto';
	@font-weight:	500;
	@font-style:	normal;
	@svg-id:		'robotomedium';
    @local:         ~"local('Roboto Medium'),";
);

// 500 Medium Italic
.font-face(
    @font-path:     'roboto/';
	@file-name:		'roboto-mediumitalic';
	@font-family:	'Roboto';
	@font-weight:	500;
	@font-style:	italic;
	@svg-id:		'robotomedium_italic';
    @local:         ~"local('Roboto Medium Italic'),";
);

// 700 Bold
.font-face(
    @font-path:     'roboto/';
	@file-name:		'roboto-bold';
	@font-family:	'Roboto';
	@font-weight:	700;
	@font-style:	normal;
	@svg-id:		'robotobold';
    @local:         ~"local('Roboto Bold'),";
);

// 700 Bold Italic
.font-face(
    @font-path:     'roboto/';
	@file-name:		'roboto-bolditalic';
	@font-family:	'Roboto';
	@font-weight:	700;
	@font-style:	italic;
	@svg-id:		'robotobold_italic';
    @local:         ~"local('Roboto Bold Italic'),";
);

// 300 Light Condensed (toolbar)
.font-face(
    @font-path:     'roboto/';
	@file-name:		'robotocondensed-light';
	@font-family:	'Roboto Condensed';
	@font-weight:	300;
	@font-style:	normal;
	@svg-id:		'roboto_condensedlight';
    @local:         ~"local('Roboto Condensed Light'),";
);

// 300 Light Condensed Italic (toolbar)
.font-face(
    @font-path:     'roboto/';
	@file-name:		'robotocondensed-lightitalic';
	@font-family:	'Roboto Condensed';
	@font-weight:	300;
	@font-style:	italic;
	@svg-id:		'roboto_condensedlight_italic';
    @local:         ~"local('Roboto Condensed Thin Italic'),";
);
